import axios from "axios";

window.onload = function () {
    const hideMushroomButton = document.getElementById('hide-mushroom');
    const mushroomCharacterDiv = document.getElementById('mushroom-character');
    const contentDiv = mushroomCharacterDiv.querySelector('.content');
    const mushroomImage = mushroomCharacterDiv.querySelector('img');

    hideMushroomButton.addEventListener('click', function (e) {
        e.stopPropagation();
        contentDiv.classList.add('visually-hidden');

        axios.get('/hide-mushroom')
            .catch(function (error) {
                console.log(error);
            });
    });

    mushroomImage.addEventListener('click', function (e) {
        e.preventDefault();

        contentDiv.classList.remove('visually-hidden');
        axios.get('/show-mushroom')
            .catch(function (error) {
                console.log(error);
            });
    });
};
