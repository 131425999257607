/**
 * This script will allow for the following:
 * - desktop navigation can hover over drop down items
 * - desktop top level items (with drop downs) click through to their respective pages
 * - mobile (under BREAKPOINT) can toggle drop down items
 * - mobile can still click through to sub-pages but cannot click through to top level pages with drop downs
 *
 * Overall was inspired by various sources online and then customized to our needs, particularly around
 * the mobile behaviour
 *
 * @author Dan Klassen <dan@triplei.ca>
 */
document.addEventListener("DOMContentLoaded", function () {
    const BREAKPOINT = 1200;

    // Bootstrap makes top level drop down items not clickable - we want to override that
    document.querySelectorAll('.navbar .nav-item a.nav-link').forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            window.location = this.href;
        });
    });

    if (window.innerWidth > BREAKPOINT) {
        document.querySelectorAll('.navbar .nav-item').forEach(function (item) {
            // bootstrap 5 makes drop down items only show on click - we want them to show / hide on hover
            item.addEventListener('mouseover', function (e) {
                let linkElm = this.querySelector('a[data-bs-toggle]');

                if (linkElm !== null) {
                    let nextEl = linkElm.nextElementSibling;
                    linkElm.classList.add('show');
                    nextEl.classList.add('show');
                }
            });
            item.addEventListener('mouseleave', function (e) {
                let linkElm = this.querySelector('a[data-bs-toggle]');

                if (linkElm !== null) {
                    let nextEl = linkElm.nextElementSibling;
                    linkElm.classList.remove('show');
                    nextEl.classList.remove('show');
                }
            });
        });

    } else {
        document.querySelectorAll('.navbar .nav-item .dropdown-arrow').forEach(function (item) {
            // we want to make the drop arrows toggle the sub-navigation on touch on small screens
            item.addEventListener('click', function (e) {
                e.preventDefault();
                let arrow = this;
                let listItem = arrow.parentNode;

                if (listItem.classList.contains('show')) {
                    listItem.classList.remove('show');
                } else {
                    listItem.classList.add('show');
                }
            });
        });
    }
});
