(function () {
    const headerScrollClass = function () {
        const scrollPosition = document.documentElement.scrollTop;
        const pageHeader = document.getElementById('page-header');
        const navElements = pageHeader.querySelectorAll('nav');
        if (navElements.length === 0) {
            return;
        }
        const nav = navElements[0];
        if (scrollPosition > 0) {
            nav.classList.add('scrolled');
        } else {
            nav.classList.remove('scrolled');
        }
    }
    window.addEventListener('scroll', headerScrollClass);
})();